import request from '../axios';

/* 获取初始化客服数据 */
export const initialize = (params) => {
    return request({
        url: '/addons/kefu/index/initialize?modulename=index',
        root: true,
        method: 'post',
        data: params
    })
}

/* 获取知识库列表 */
export const getkbs = (params) => {
    return request({
        url: 'kefu/getKbs',
        method: 'get',
        data: params
    })
}

export default {
    initialize,
    getkbs
}