import Vue from 'vue'
import App from './App.vue'
import router from './router'
// api
import api from './request/api.js';
Vue.prototype.$api = api

// vuex
let vuexStore = require('@/store/$ku.mixin.js')
Vue.mixin(vuexStore.kuStore)

// 复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// element 按需引入
import '@/util/element.js'

// 图片预览插件 vue-photo-preview
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

// 公共方法
import utils from '@/util/util.js'
Vue.prototype.utils = utils

// app下载链接
Vue.prototype.AppDownUrl = "http://m.yidalao.com/api/app/down"

Vue.config.productionTip = false

new Vue({
  router,
  store: vuexStore.store,
  render: h => h(App)
}).$mount('#app')
