import request from '../axios';
import store from "@/store/index"

/* 添加项目测试图 */
export const add = (params) => {
    return request({
        url: 'project_testimg/add',
        method: 'post',
        data: params
    })
}

/* 获取推荐列表 */
export const recommend_list = (params = {}) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'project_testimg/recommend_list',
        method: 'post',
        data: params
    })
}

/* 获取测试图详情 */
export const detail = (params) => {
    return request({
        url: 'project_testimg/detail',
        method: 'post',
        data: params
    })
}

export default {
    add,
    recommend_list,
    detail
}