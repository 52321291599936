import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let lifeData = {}

try {
    lifeData = JSON.parse(localStorage.getItem('lifeData'));
} catch (e) {
    console.error('vuex catch:', e)
}

let saveStateKeys = ['vuex_userinfo', 'isLogin', 'kefu_tourists_token', 'drawing_type', 'userType', 'platform'];

const saveLifeData = function (key, value) {
    if (saveStateKeys.indexOf(key) !== -1) {
        let tmp = JSON.parse(localStorage.getItem('lifeData'));
        tmp = tmp || {};
        tmp[key] = value;
        localStorage.setItem('lifeData', JSON.stringify(tmp));
    }
}

const store = new Vuex.Store({
    state: {
        vuex_userinfo: lifeData?.vuex_userinfo || {},
        isLogin: lifeData?.isLogin === true,
        kefu_tourists_token: lifeData?.kefu_tourists_token || '',
        drawing_type: lifeData?.drawing_type || '',
        userType: lifeData?.userType || '',
        vuex_login_member_show: lifeData?.login_member_show || false,
        vuex_login_member_type:lifeData?.vuex_login_member_type ||'login',
        platform: lifeData?.platform || '',
        memberInfo: {},
        appInfo: {},
    },
    mutations: {
        $kuStore(state, payload) {
            let nameArr = payload.name.split('.');
            let saveKey = '';
            let len = nameArr.length;
            if (len >= 2) {
                let obj = state[nameArr[0]];
                for (let i = 1; i < len - 1; i++) {
                    obj = obj[nameArr[i]];
                }
                obj[nameArr[len - 1]] = payload.value;
                saveKey = nameArr[0];
            } else {
                state[payload.name] = payload.value;
                saveKey = payload.name;
            }
            saveLifeData(saveKey, state[saveKey])
        }
    }
});

export default store
