import request from '../axios';

/* 轮播列表 */
export const list = (params) => {
    return request({
        url: 'home_bg_swiper/list',
        method: 'get',
        data: params
    })
}

export default {
    list
}