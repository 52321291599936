// 手机
export default [
    {
        path: '/mobile',
        meta: {
            title: '首页'
        },
        component: () => import('../views/mobile/Home.vue'),
    },
    {
        path: '/mobile_login',
        meta: {
            title: '登录'
        },
        component: () => import('../views/mobile/login.vue'),
    },
    {
        path: '/project',
        meta: {
            title: '项目'
        },
        component: () => import('../views/mobile/project.vue'),
    },
    {
        path: '/receive',
        name: '/receive',
        meta: {
            title: '接收'
        },
        component: () => import('../views/mobile/receive.vue'),
    },
    {
        path: '/send_image',
        meta: {
            title: '发图'
        },
        component: () => import('../views/mobile/send_image.vue'),
    },
    {
        path: '/feedback',
        meta: {
            title: '反馈'
        },
        component: () => import('../views/mobile/feedback.vue'),
    },
    {
        path: '/feedback_list',
        meta: {
            title: '反馈列表'
        },
        component: () => import('../views/mobile/feedback_list.vue'),
    },
]