import axios from 'axios';
import store from '@/store/index';
import { Message } from 'element-ui';
import utils from '../util/util.js';
import router from '@/router';

axios.defaults.baseURL = 'https://m.yidalao.com/';
axios.defaults.wsURL = 'wss://m.yidalao.com/wss';

// 请求拦截器
axios.interceptors.request.use(config => {
    config.baseURL += config.root ? '' : 'api/'
    if (config.contentType) config.headers['Content-Type'] = config.contentType
    config.headers.token = store.state.vuex_userinfo.token ?? ''; //将token放到请求头发送给服务器
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
    if (response.data.code === 1) {
        if (response.data.msg && response.data.msg != 'ok') Message.success(response.data.msg);
        return Promise.resolve(response.data);
    }
    Message.error(response.data.msg);
    return Promise.reject(response);
}, error => {
    let { msg, code } = error.response.data
    Message.error(msg);
    if (code == 401) {
        // 显示登录框
        store.commit('$kuStore', {
            name: 'vuex_login_member_show', value: true
        })
        // 清空用户信息
        store.commit('$kuStore', {
            name: 'vuex_userinfo', value: {}
        })
        // 变更登录状态
        store.commit('$kuStore', {
            name: 'isLogin', value: false
        })
        if (utils._isMobile()) {
            router.push('/mobile_login')
        }
    }
    return Promise.reject(error);
});

export default axios;
