import request from '../axios';

/* 添加建议 */
export const add = (params) => {
    return request({
        url: 'advice/add',
        method: 'post',
        data: params
    })
}

export default {
    add
}