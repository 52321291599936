import request from '../axios';

/* 视频列表 */
export const list = (params) => {
    return request({
        url: 'video/list',
        method: 'get',
        data: params
    })
}

/* 添加视频 */
export const add = (params) => {
    return request({
        url: 'video/add',
        method: 'post',
        data: params
    })
}

/* 添加视频 */
export const payment = (params) => {
    return request({
        url: 'video/payment',
        method: 'post',
        data: params
    })
}

/* 检查订单状态 */
export const check_order = (params) => {
    return request({
        url: 'video/check_order',
        method: 'post',
        data: params
    })
}

export default {
    list,
    add,
    payment,
    check_order
}