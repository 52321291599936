<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.utils._isMobile()) {
      this.$ku.vuex("platform", "");
    }
    if (this.utils._isMobile() && this.platform != "mobile") {
      this.$router.replace("/mobile");
      this.$ku.vuex("platform", "mobile");
    } else if (!this.utils._isMobile() && this.platform == "mobile") {
      this.$router.replace("/");
      this.$ku.vuex("platform", "");
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.input-with-select .el-input-group__prepend {
  padding: 0 3%;
  background-color: #000;
}
.input-with-select .el-input__inner {
  border-left: 0;
}

.el-input__inner,
.el-textarea__inner {
  background-color: rgba(0, 0, 0, 0);
  height: 2vw;
}
.el-form-item {
  margin-bottom: 0;
}
.el-form-item__label {
  font-size: 12px;
  line-height: unset;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}
.RoleHeader-inputs .el-input__inner {
  height: 2.5vw;
  margin-bottom: 0.9vw;
}
.el-dialog__body {
  padding: 0;
}
*::-webkit-scrollbar {
  display: none;
}
.el-upload--picture-card {
  position: relative;
  background: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  background: none;
}
.el-loading-mask {
  background: none;
}
input,
textarea {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.app-down {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}
.app-down .app-down-main {
  padding: 20px;
  padding-bottom: 10px;
  border: 1px solid #009AB1;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  position: relative;
}
.app-down .app-down-main .close {
  position: absolute;
  right:-30px;
  top:-25px;
  font-size: 24px;
  cursor: pointer;
}
</style>
