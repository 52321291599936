import user from './apis/user'
import kefu from './apis/kefu'
import general from './apis/general'
import category from './apis/category'
import common from './apis/common'
import project from './apis/project'
import projectPhase from './apis/projectPhase'
import projectTestimg from './apis/projectTestimg'
import projectDocument from './apis/projectDocument'
import clock from './apis/clock'
import video from './apis/video'
import advice from './apis/advice'
import score from './apis/score'
import slideshow from './apis/slideshow'
import sms from './apis/sms'
import home_bg_swiper from './apis/home_bg_swiper'
import styles from './apis/styles'
import contract from './apis/contract'
import mobile_project_images from './apis/mobile_project_images'
import mobile_project_opinion from './apis/mobile_project_opinion'
import nft_product from './apis/nft_product'
import nft_member from './apis/nft_member'
import nft_app from './apis/nft_app'

export default {
    user,
    kefu,
    general,
    category,
    common,
    project,
    projectPhase,
    projectTestimg,
    projectDocument,
    clock,
    video,
    advice,
    score,
    slideshow,
    sms,
    home_bg_swiper,
    styles,
    contract,
    mobile_project_images,
    mobile_project_opinion,
    nft_product,
    nft_member,
    nft_app,
}
