import request from '../axios';

/* 获取App下载地址 */
export const style = (params) => {
    return request({
        url: 'category/style',
        method: 'get',
        data: params
    })
}

export default {
    style
}