import request from '../axios';

/* 提交合同 */
export const submit = (params) => {
    return request({
        url: 'contract/submit',
        method: 'POST',
        data: params
    })
}

/* 是否签署合同 */
export const is_contract = (params) => {
    return request({
        url: 'contract/is_contract',
        method: 'POST',
        data: params
    })
}

export default {
    submit,
    is_contract
}