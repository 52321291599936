import request from '../axios';
import store from "@/store/index"

/* 风格列表 */
export const list = (params = {}) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'styles/list',
        method: 'post',
        data: params
    })
}

export default {
    list
}