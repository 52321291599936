import request from '../axios';

/* 累计打卡 */
export const total = (params) => {
    return request({
        url: 'clock/total',
        method: 'get',
        data: params
    })
}

/* 打卡 */
export const add = (params) => {
    return request({
        url: 'clock/add',
        method: 'get',
        data: params
    })
}

export default {
    total,
    add
}