import request from '../axios';
import store from "@/store/index"

/* 登录 */
export const login = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'user/login',
        method: 'post',
        data: params
    })
}

/* 手机验证码登录 */
export const mobilelogin = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'user/mobilelogin',
        method: 'post',
        data: params
    })
}

/* 注册 */
export const register = (params) => {
    return request({
        url: 'user/register',
        method: 'post',
        data: params
    })
}

/* 风格 */
export const styles = (params = {}) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'user/styles',
        method: 'post',
        data: params
    })
}

/* 设置风格 */
export const set_style = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'user/set_style',
        method: 'post',
        data: params
    })
}

/* 累计成就 */
export const achievement = (params) => {
    return request({
        url: 'user/achievement',
        method: 'post',
        data: params
    })
}

/* 退出登录 */
export const logout = (params) => {
    return request({
        url: 'user/logout',
        method: 'post',
        data: params
    })
}

export default {
    login,
    register,
    styles,
    set_style,
    achievement,
    logout,
    mobilelogin
}