import request from '../axios';

/* 添加图片 */
export const add = (params) => {
    return request({
        url: 'mobile_project_images/add',
        method: 'post',
        data: params
    })
}

/* 图片列表 */
export const list = (params) => {
    return request({
        url: 'mobile_project_images/list',
        method: 'post',
        data: params
    })
}

export default {
    add,
    list
}