import request from '../axios';

/* 项目列表 */
export const list = (params) => {
    return request({
        url: 'project_phase/list',
        method: 'post',
        data: params
    })
}

export default {
    list,
}