import Vue from 'vue'
import VueRouter from 'vue-router'

import enterprise from './enterprise'
import painter from './painter'
import mobile from './mobile'
import nft from './nft'
import ai_bai_bao_xiang from "./ai_bai_bao_xiang";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      title: '首页'
    },
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'nft/list',
        meta: {
          title: '藏品列表'
        },
        component: () => import('../views/nft/list.vue'),
      },
    ]
  },
]
  .concat(enterprise)
  .concat(painter)
  .concat(mobile)
  .concat(nft)
  .concat(ai_bai_bao_xiang)

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
