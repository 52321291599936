import Vue from 'vue'

import { upload, input, Select, Option, form, formItem, dialog, datePicker, loading, message, button, Notification, Table, TableColumn, Pagination, MessageBox} from 'element-ui';
Vue.use(upload);
Vue.use(input);
Vue.use(Select);
Vue.use(Option);
Vue.use(form);
Vue.use(formItem);
Vue.use(dialog);
Vue.use(datePicker);
Vue.use(loading);
Vue.use(button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.prototype.$message = message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
