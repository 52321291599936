import request from '../axios';

/* 累计积分 */
export const count = (params) => {
    return request({
        url: 'score/count',
        method: 'get',
        data: params
    })
}

export default {
    count
}