import request from '../axios';
import store from "@/store/index"
/* 添加项目文案 */
export const add = (params) => {
    return request({
        url: 'project_document/add',
        method: 'post',
        data: params
    })
}

/* 添加项目文案 */
export const list = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'project_document/list',
        method: 'post',
        data: params
    })
}

/* 接收项目文案 */
export const receive = (params) => {
    return request({
        url: 'project_document/receive',
        method: 'post',
        data: params
    })
}

export default {
    add,
    list,
    receive
}