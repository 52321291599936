import request from '../axios';
import store from "@/store/index"

/* 发布项目 */
export const add = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'project/add',
        method: 'post',
        data: params
    })
}

/* 项目列表 */
export const list = (params = {}) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'project/list',
        method: 'post',
        data: params
    })
}

/* 接单 */
export const take_order = (params) => {
    return request({
        url: 'project/take_order',
        method: 'post',
        data: params
    })
}

/* 项目阶段列表 */
export const phase_list = (params) => {
    if (params.is_drawing_type !== false) {
        params.drawing_type = store.state.drawing_type
    }
    delete params.is_drawing_type
    return request({
        url: 'project/phase_list',
        method: 'post',
        data: params
    })
}

/* 选择画师 */
export const choose_painter = (params) => {
    return request({
        url: 'project/choose_painter',
        method: 'post',
        data: params
    })
}

/* 完成项目 */
export const complete = (params) => {
    return request({
        url: 'project/complete ',
        method: 'post',
        data: params
    })
}

export default {
    add,
    list,
    take_order,
    phase_list,
    choose_painter,
    complete
}