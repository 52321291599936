import store from "@/store/index"
import { mapState } from 'vuex'
import Vue from 'vue'

Vue.prototype.$ku = {}

let $kuStoreKey = [];
$kuStoreKey = store.state ? Object.keys(store.state) : [];

const kuStore = {
    beforeCreate() {
        this.$ku.vuex = (name, value) => {
            store.commit('$kuStore', {
                name, value
            })
        }
    },
    computed: {
        ...mapState($kuStoreKey)
    }
}

export { store, kuStore }