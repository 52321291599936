import request from '../axios';
import store from "@/store/index"

/* 添加 */
export const add = (params) => {
    params.drawing_type = store.state.drawing_type
    return request({
        url: 'slideshow/add',
        method: 'post',
        data: params
    })
}

/* 列表 */
export const list = (params) => {
    return request({
        url: 'slideshow/list',
        method: 'get',
        data: params
    })
}

export default {
    add,
    list
}