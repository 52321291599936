import request from '../axios';
// 读取会员信息
export const getMemberInfo = () => {
    return request({
        url:'member/getInfo',
        method: 'post'
    })
}
export default {
    getMemberInfo,
}

