import request from '../axios';

/* 发送验证码 */
export const send = (params) => {
    return request({
        url: 'sms/send',
        method: 'post',
        data: params
    })
}

export default {
    send
}