// 企业
export default [
    {
        path: '/enterprise',
        meta: {
            title: '企业首页'
        },
        component: () => import('../views/enterprise/Home.vue'),
        children: [
            {
                path: 'recommend',
                meta: {
                    title: '企业-推荐'
                },
                component: () => import('../views/enterprise/Recommend.vue'),
            },
            {
                path: 'test',
                meta: {
                    title: '企业-测试'
                },
                component: () => import('../views/enterprise/Test.vue'),
            },
            {
                path: 'cooperation',
                meta: {
                    title: '企业-合作'
                },
                component: () => import('../views/enterprise/Cooperation.vue'),
            },
            {
                path: 'discounts',
                meta: {
                    title: '企业-优惠'
                },
                component: () => import('../views/enterprise/Discounts.vue'),
            },
            {
                path: 'communication',
                name: '/enterprise/communication',
                meta: {
                    title: '企业-沟通'
                },
                component: () => import('../views/enterprise/Communication.vue'),
            },
            {
                path: 'advice',
                meta: {
                    title: '企业-建议'
                },
                component: () => import('../views/enterprise/Advice.vue'),
            },
            {
                path: 'task',
                meta: {
                    title: '企业-任务管理'
                },
                component: () => import('../views/enterprise/Task.vue'),
            },
            {
                path: 'integral',
                meta: {
                    title: '企业-每日积分'
                },
                component: () => import('../views/enterprise/Integral.vue'),
            },
            {
                path: 'discounts_header',
                meta: {
                    title: '企业-累计优惠'
                },
                component: () => import('../views/enterprise/Discounts_header.vue'),
            }
        ]
    },
]