import request from '../axios';

/* 获取App下载地址 */
export const download_address = (params) => {
    return request({
        url: 'general/download_address',
        method: 'post',
        data: params
    })
}

/* 获取公告 */
export const announcement = (params) => {
    return request({
        url: 'general/announcement',
        method: 'post',
        data: params
    })
}

export default {
    download_address,
    announcement
}