import request from '../axios';

/* 上传文件 */
export const upload = (params) => {
    return request({
        url: 'common/upload',
        method: 'post',
        contentType: 'multipart/form-data',
        data: params
    })
}

export default {
    upload
}