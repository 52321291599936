// 企业
export default [
    {
        path: '/nft',
        meta: {
            title: 'NFT'
        },
        component: () => import('../views/nft/Home.vue'),
        children: [
            {
                path: 'detail',
                meta: {
                    title: '藏品详情'
                },
                component: () => import('../views/nft/detail.vue'),
            },
            {
                path: 'upload',
                meta: {
                    title: '藏品上传'
                },
                component: () => import('../views/nft/upload.vue'),
            },
            {
                path: 'management',
                meta: {
                    title: '藏品管理'
                },
                component: () => import('../views/nft/management.vue'),
            },
        ]
    },
]
