// 企业
export default [
    {
        path: '/painter',
        meta: {
            title: '画手首页'
        },
        component: () => import('../views/painter/Home.vue'),
        children: [
            {
                path: 'take_orders',
                meta: {
                    title: '画手-接单'
                },
                component: () => import('../views/painter/Take_orders.vue'),
            },
            {
                path: 'order_management',
                name: 'order_management',
                meta: {
                    title: '画手-接单管理'
                },
                component: () => import('../views/painter/Order_management.vue'),
            },
            {
                path: 'communication',
                name: '/painter/communication',
                meta: {
                    title: '画手-沟通'
                },
                component: () => import('../views/painter/Communication.vue'),
            },
            {
                path: 'settlement',
                meta: {
                    title: '画手-结算'
                },
                component: () => import('../views/painter/Settlement.vue'),
            },
            {
                path: 'dividend',
                meta: {
                    title: '画手-分红'
                },
                component: () => import('../views/painter/Dividend.vue'),
            },
            {
                path: 'college',
                meta: {
                    title: '画手-学院'
                },
                component: () => import('../views/painter/College.vue'),
            },
            {
                path: 'tool',
                meta: {
                    title: '画手-工具'
                },
                component: () => import('../views/painter/Tool.vue'),
            },
            {
                path: 'daily_clock',
                meta: {
                    title: '画手-每日打卡'
                },
                component: () => import('../views/painter/Daily_clock.vue'),
            },
            {
                path: 'cumulative_achievements',
                meta: {
                    title: '画手-累计成就'
                },
                component: () => import('../views/painter/Cumulative_achievements.vue'),
            },
            {
                path: 'uploading',
                meta: {
                    title: '画手-上传作品'
                },
                component: () => import('../views/painter/Uploading.vue'),
            }
        ]
    },
]