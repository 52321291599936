import request from '../axios';
// 获取app初始化信息
export const getAppInfo = () => {
    return request({
        url:'app/init',
        method: 'post'
    })
}
export default {
    getAppInfo,
}

