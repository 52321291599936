// ai百宝箱
export default [
    {
        path:"/ai_bai_bao_xiang",
        meta: {
            title: 'AI百宝箱'
        },
        component: () => import('../views/ai_bai_bao_xiang/Home.vue'),

    }
]
