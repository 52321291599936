import request from '../axios';

// 读取分类列表
export const getCateList = () => {
    return request({
        url:'member_collection/getCateList',
        method: 'get'
    })
}
// 获取所有会员藏品列表
export const getList = (form) => {
    return request({
        url: 'member_collection/getList',
        method: 'post',
        data: form,
    })
}
// 获取会员藏品列表
export const getMemberList = (form) => {
    return request({
        url: 'member_collection/getMemberList',
        method: 'post',
        data: form,
    })
}
// 藏品上下架处理
export const switchProductStatus = (form) => {
    return request({
        url: 'member_collection/switchStatus',
        method: 'post',
        data: form,
    })
}
// 新增/编辑藏品信息
export const updateProduct = (form) => {
    return request({
        url: 'member_collection/update',
        method: 'post',
        data: form,
    })
}
// 读取藏品信息
export const getProductInfo = (form) => {
    return request({
        url: 'member_collection/getInfo',
        method: 'post',
        data: form,
    })
}

export default {
    getCateList,
    getList,
    getMemberList,
    switchProductStatus,
    updateProduct,
    getProductInfo
}
